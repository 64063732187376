@font-face {
    font-family: bernhc;
    src: url("assets/fonts/bernhc.ttf")
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /*background-color: #1C586B;*/
    /*color: white;*/
    text-align: center;
}
.footerLink {
    display: block;
    width: 100%;
    height: 100%;
    transition: background-color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #1C586B;
    color: white;
    padding-bottom: 0.75vh;
    padding-top: 0.75vh;
}
.footerLink:hover {
    background-color: #0C485B;
    color: white;
    text-decoration: none;
    transition: background-color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.mainTitle {
    margin-bottom: 10px;
    font: 120px "bernhc", bernhc, sans-serif;
    color: #1930a1;
    /*font-family: "American Typewriter", serif;*/
}
hr.solid {
    border-top: 3px solid #9CA8BB;
}
h1 {
    font: 120px "bernhc", bernhc, sans-serif;
    font-size: 150%;
    color: #1C586B;
}
h2, h3 {
    color: #1C586B;
}
b, p {
    color: #03404C;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
    background-color: #282c3430;
    /*opacity: 1;*/
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
    opacity: 1;
    /*height: 100px;*/
    /*width: 100px;*/
    /*outline: black;*/
    /*background-size: 100%, 100%;*/
    /*border-radius: 50%;*/
    /*border: 1px solid black;*/
    /*background-image: none;*/
    /*background-color: #000000;*/
}

.carousel-control-next-icon:after
{
    /*content: '>';*/
    /*font-size: 55px;*/
    /*color: red;*/
}

.carousel-control-prev-icon:after {
    /*content: '<';*/
    /*font-size: 55px;*/
    /*color: red;*/
}
.bimco {
    width:540px; /* you can use % */
    height: auto;
}
.carousel-inner {
    /*max-height: 100%;*/
}
.carousel-item img {
    max-height: calc(100vh - 138px);
    max-width: auto;
    margin: auto;
    display: block;
}


body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, bernhc, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
